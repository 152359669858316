/****************************************************************
 *
 * CSS Percentage Circle
 * Author: Andre Firchow
 *
*****************************************************************/

// Compass utilities
@import 'node_modules/compass-sass-mixins/lib/compass';
@import "variables";


// VARS
$circle-width: 0.08em;
$circle-width-hover: 0.04em;

// colors default
$primary-color: #307bbb;
$secondary-color: $pozi_light;
$bg-color: white;

$primary-color-green: $wine_green;
$primary-color-yellow: $scs_yellow;
$primary-color-red: $pozi_red;


// CIRCLE
// classes 2 extend
.rect-auto {
  clip: rect(auto, auto, auto, auto);
}

.pie {
  position: absolute;
  border: $circle-width solid $primary-color;
  width: 1 - (2 * $circle-width);
  height: 1 - (2 * $circle-width);
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  @include rotate(0deg);
}

.pie-fill {
  @include rotate(180deg);
}


// main
.c100 {

  *, *:before, *:after {
    @include box-sizing(content-box);
  }

  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: $secondary-color;

  // center circle to its parent
  &.center {
    float: none;
    margin: auto auto;
  }

  // bigger size
  &.big {
    font-size: 240px;
  }

  // smaller size
  &.small {
    font-size: 80px;
  }

  // centered value inside circle
  > span {


    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    line-height: 5em;
    font-size: 0.2em;
    color: $pozi_grey;
    display: block;
    text-align: center;
    white-space: nowrap;
    @include transition-property(all);
    @include transition-duration(0.2s);
    @include transition-timing-function(ease-out);

    &.top {
      color: $pozi_black;
      font-size: 20px;
      line-height: normal;
      top: calc(50% - 23px);
    }

    &.bottom {
      font-size: 12px;
      line-height: normal;
      top: calc(50% + 5px);
    }
  }

  // background inside the circle
  &:after {
    position: absolute;
    top: $circle-width;
    left: $circle-width;
    display: block;
    content: " ";
    border-radius: 50%;
    background-color: $bg-color;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
    @include transition-property(all);
    @include transition-duration(0.2s);
    @include transition-timing-function(ease-in);

  }

  // the slice (mask)
  .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0em, 1em, 1em, 0.5em);
  }

  // circle to show the status
  .bar {
    @extend .pie;
  }


  // loop to create all needed elements automatically
  @for $j from 51 through 100 {

    &.p#{$j} .slice {
      @extend .rect-auto;
    }

    &.p#{$j} .bar:after {
      @extend .pie-fill;
    }

    &.p#{$j} .fill {
      @extend .pie;
      @extend .pie-fill;
    }

  }

  // loop to rotate all 100 circles
  @for $j from 1 through 100 {
    &.p#{$j} .bar {
      @include rotate((360*0.01*$j) + deg);
    }
  }


  // hover styles
  /*&:hover {

    cursor: default;

    > span {
      width: 3.33em;
      line-height: 3.33em;
      font-size: 0.3em;
      color: $primary-color;
    }

    &:after {
      top: $circle-width-hover;
      left: $circle-width-hover;
      width: 1 - (2 * $circle-width-hover);
      height: 1 - (2 * $circle-width-hover);
    }

  }*/


  // green skin
  &.green {

    .bar, .fill {
      border-color: $primary-color-green !important;
    }

    /*&:hover {
      > span {
        color: $primary-color-green;
      }
    }*/

  }


  // yellow skin
  &.yellow {

    .bar, .fill {
      border-color: $primary-color-yellow !important;
    }

    /*&:hover {
      > span {
        color: $primary-color-yellow;
      }
    }*/
  }

  // red skin
  &.red {

    .bar, .fill {
      border-color: $primary-color-red !important;
    }

    /*&:hover {
      > span {
        color: $primary-color-red;
      }
    }*/

  }
}
