// Colors
$pozi_white: #F4F8FC;
$pozi_light: #E8EEF4;
$pozi_light_svg: brightness(0) saturate(100%) invert(93%) sepia(18%) saturate(70%) hue-rotate(169deg) brightness(100%) contrast(93%);
$pozi_grey: #a0aab4;
$pozi_grey_svg: brightness(0) saturate(100%) invert(72%) sepia(18%) saturate(169%) hue-rotate(169deg) brightness(91%) contrast(88%);
$pozi_dark: #505a64;
$pozi_dark_svg: brightness(0) saturate(100%) invert(33%) sepia(14%) saturate(532%) hue-rotate(169deg) brightness(93%) contrast(82%);
$pozi_black: #141e28;
$pozi_black_svg: brightness(0) saturate(100%) invert(8%) sepia(27%) saturate(903%) hue-rotate(168deg) brightness(103%) contrast(93%);
$pozi_red: #f05014;
$pozi_red_svg: brightness(0) saturate(100%) invert(32%) sepia(88%) saturate(2300%) hue-rotate(358deg) brightness(101%) contrast(88%);
$scs_yellow: #f0c800;
$scs_yellow_svg: brightness(0) saturate(100%) invert(78%) sepia(70%) saturate(619%) hue-rotate(356deg) brightness(91%) contrast(106%);
$wine_green: #a0c800;
$wine_green_svg: brightness(0) saturate(100%) invert(78%) sepia(21%) saturate(6382%) hue-rotate(28deg) brightness(98%) contrast(101%);
$hydrolog_blue: #14b4f0;
$hydrolog_blue_svg: brightness(0) saturate(100%) invert(67%) sepia(27%) saturate(6418%) hue-rotate(160deg) brightness(98%) contrast(92%);
$divider-gray: #b4c8dc;
$divider-gray-svg: brightness(0) saturate(100%) invert(89%) sepia(4%) saturate(1890%) hue-rotate(180deg) brightness(92%) contrast(87%);

$white_svg: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(280deg) brightness(105%) contrast(101%);

$new_grey: #d9dde1;

$popup_overlay_bg: #14181c66;
$popup_bg: #fdfdfd;

$pozi_green: #8cc814;

$text-color-primary: $pozi_black;
$text-color-secondary: $pozi_red;
$text-color-secondary-svg: $pozi_red_svg;
$text-color-secondary-hover: darken($pozi_red, 10%);
$text-color-error: $pozi_red;

$button-background-color: $pozi_red;
$button-background-color-hover: darken($pozi_red, 10%);

$layer-background-color: darken($pozi_red, 10%);

$icon-color-primary: $pozi_red;
$icon-color-primary-hover: darken($pozi_red, 10%);

$input-background: $pozi_light;
$input-label-color: $pozi_dark;
$input-svg-color: $pozi_dark;

$manage-grey: #b3c7db;

$tag_bg: $pozi_light;
$tag_icon_bg: #8b9fb3;
$grey_font: #8ca0b4;
$icon_color: #2b333b;
$icon_color_svg: brightness(0) saturate(100%) invert(16%) sepia(20%) saturate(519%) hue-rotate(168deg) brightness(93%) contrast(89%);
$tag_icon_color: brightness(0) saturate(100%) invert(64%) sepia(23%) saturate(320%) hue-rotate(169deg) brightness(95%) contrast(82%);

$form-control-icon-color: #2c343c;

$subheader-background-color: $pozi_light;

// Other

$font-size: 16px;
$button-secondary-size: 20px;
$sides-padding: 10px;

$row-height: 45px;
$row-height-login: 45px;
$row-height-home: 60px;
$row-height-home-edit-node: 50px;

$header-height: 60px;
$subheader-height: 60px;
$tab-height: 30px;
$subheader-w-tabs-height: $subheader-height + $tab-height - 10px;
$sidebar-width: 160px;
$sidebar-padding: 17px;

@mixin collision-popup {
  app-popup.collision-popup {
    ::ng-deep .popup {
      padding: 0 !important;

      div.content {
        height: 100%;
        margin-bottom: 0;
        overflow: auto;
      }
    }

    ::ng-deep .titlebar {
      padding: 20px 20px 0 20px;
    }

    .popup-content {
      height: 100%;

      .collision {
        display: flex;
        padding-left: 12px;

        > img {
          background-color: #{$pozi_red + '44'};
          border-radius: 50%;
          filter: $pozi_red_svg;
          height: 32px;
          margin-right: 14px;
          margin-top: 12px;
          padding: 8px;
          width: 32px;
        }

        > div {
          border-top: 1px solid $pozi_light;
          min-width: 300px;
          margin-right: 34px;
          width: 100%;

          > span {
            display: block;

            &.bold {
              padding-top: 14px;
            }
          }

          > div {
            align-items: center;
            border: 1px solid $pozi_light;
            border-radius: 4px;
            display: flex;
            margin-bottom: 14px;
            margin-top: 14px;
            width: 100%;

            > div {
              align-items: center;
              display: flex;
              padding: 6px;

              &:first-of-type {
                border-right: 1px solid $pozi_light;
              }

              > img {
                filter: $pozi_dark_svg;
                height: 20px;
                margin-right: 4px;
                width: 20px;
              }

              > i {
                color: $pozi_dark;
                font-size: 20px;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }

  .popup-footer {
    .btn {
      margin: 0 20px 20px 20px;
    }
  }
}

:root {
  --green: #{$wine_green};
  --green-svg: #{$wine_green_svg};
  --yellow: #{$scs_yellow};
  --yellow-svg: #{$scs_yellow_svg};
  --light-grey: #{$pozi_light};
  --light-grey-svg: #{$pozi_light_svg};
  --grey: #{$pozi_grey};
  --grey-svg: #{$pozi_grey_svg};
  --dark-grey: #{$pozi_dark};
  --dark-grey-svg: #{$pozi_dark_svg};
  --subheader-background: #{$pozi_light};
  --header-height: #{$header-height};
  --subheader-one-row-height: #{$subheader-height};
  --subheader-second-row-height: #{$tab-height};
  --subheader-tabs-height: #{$tab-height};
  --subheader-w-tabs-height: #{$subheader-w-tabs-height};
  --red: #{$pozi_red};
  --primary-color: #{$pozi_red};
  --secondary-color: #{$pozi_light};
  --input-grey: #{$pozi_light};
  --text-color: #{$pozi_black};
  --table-header-active-background: #{$pozi-black};
  --table-header-inactive-background: #{$pozi-dark};
  --table-alternate-row-background: #{rgba($pozi_red, 0.2)};
}
